import Vue from 'vue'
import VueRouter from 'vue-router'
import Inventory from '../views/Inventory.vue'
import Home from '../views/Home.vue'
import Grading from '../views/Grading.vue'
import Contact from '../views/Contact.vue'

//import products from '..components/Products.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/grading',
    name: 'Grading',
    component: Grading
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }

]

const router = new VueRouter({
	mode: "history",
	routes
});

export default router
