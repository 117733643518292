
<template>
    <div>

<section class="py-12 bg-gray-50 sm:py-16 lg:py-20">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-left sm:mx-auto sm:text-center lg:max-w-2xl">
            <h2 class="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Let's grow together</h2>
            <p class="mt-4 text-lg text-gray-700 md:max-w-md md:mx-auto sm:mt-6 font-pj">Contact us if you'd like to make a purchase, or if you have any questions/inquires.</p>
        </div>

        <div class="relative mt-12 md:mt-16">
            <div class="absolute -inset-4 lg:-inset-y-6 lg:-inset-x-8">
                <div class="w-full h-full max-w-4xl mx-auto opacity-20 blur-lg filter" style="background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"></div>
            </div>

            <div class="relative max-w-3xl mx-auto sm:flex sm:items-center sm:space-x-8 sm:justify-center">
                <div class="bg-white rounded-xl">
                    <div class="px-6 py-8">
                        <div class="flex items-start w-80">
                            <svg class="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>
                            <div class="ml-5">
                                <h3 class="text-lg font-bold text-gray-900 font-pj">WhatsApp</h3>
                                <p class="mt-3 text-base text-gray-900 font-pj"><span class="font-bold">Alex:</span> +1 (561) 596-0343</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5 bg-white rounded-xl sm:mt-0">
                    <div class="px-6 py-8">
                        <div class="flex items-start w-80">
                            <svg class="flex-shrink-0 w-6 h-6 mt-0.5 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>
                            <div class="ml-5">
                                <h3 class="text-lg font-bold text-gray-900 font-pj">Email</h3>
                                <p class="mt-3 text-base text-gray-900 font-pj">sales@cellback.net</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


    </div>
</template>

<script>
    export default {
        name: 'ContactInfo',
    }
</script>