<template>
  <div class="flex flex-col flex-1 bg-gray-50">
    <main>
      <div class="md:py-6 lg:py-6">
        <div class="px-4 max-w-8xl sm:px-6 md:px-8">
          <div class="fixed md:hidden md:items-end md:justify-between mb-3">
            <div>
              <div class="px-10 py-5">
                <div class="flow-root mt-5">
                  <div class="-my-8 divide-y divide-gray-200">
                    <div class="">
                      <div class="space-y-2 text-sm">
                        <button
                          type="button"
                          @click="hideFilters"
                          class="md:hidden inline-flex items-center px-3 py-2 text-sm font-normal leading-4 text-white bg-gray-800 border border-gray-300 rounded-lg shadow-sm lg:order-2 2xl:order-3 md:order-last focus:outline-none"
                        >
                          {{ messageButton }}
                        </button>
                        <div class="flex w-100">
                          <div class="flex items-center" v-show="showFilters">
                            <!--* Check / Uncheck to include multiple options-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="showFilters"
                      class="z-10 px-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div
                        v-show="showFilters"
                        class="py-2 border-b-2 border-gray-100 space-y-7"
                        v-for="(options, header) in headers"
                      >
                        <template
                          v-if="header !== 'Average List Price' && header !== 'Damages' && header !== 'Quantity'"
                        >
                          <button
                            type="button"
                            class="flex items-center justify-between w-full p-1 -m-1 text-base font-normal text-gray-900 transition-all duration-200 group focus:outline-none"
                            @click="showOptions[header] = !showOptions[header]"
                          >
                            <svg
                              v-if="hasFilter(currentOptions[header])"
                              class="h-2.5 w-2.5 text-blue-500"
                              fill="currentColor"
                              viewBox="0 0 8 8"
                            >
                              <circle cx="4" cy="4" r="3" />
                            </svg>
                            {{ header }}
                            <svg
                              class="w-5 h-5 text-gray-400 group-hover:text-gray-900"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                :d="
                              showOptions[header]
                                ? 'M5 15l7-7 7 7'
                                : 'M19 9l-7 7-7-7'
                            "
                              />
                            </svg>
                          </button>
                        </template>
                        <!-- FILTER OPTIONS -->
                        <div class="space-y-2" v-if="showOptions[header]">
                          <div class="flex w-100" v-for="option in options">
                            <div
                              class="flex items-center h-5"
                              @click="toggleOption(header, option)"
                            >
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                class="w-3 h-3 text-gray-900 focus:ring-gray-900"
                                v-model="currentOptions[header][option]"
                              />
                            </div>
                            <div class="text-sm">
                              <button
                                type="button"
                                class="inline items-center p-1 ml-2 -m-1 text-gray-900"
                                @click="toggleOption(header, option)"
                              >
                                {{ option }}
                                <!--({{ getItemCount(header, option) }})-->
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Quick filters-->
          <!--                                                                         <section class="py-12 bg-white sm:py-16 lg:py-20 -z-1">
                        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                            <div class="text-center">
                            <h2 class="text-2xl font-bold text-gray-900 sm:text-3xl">
                                Quick condition filters:
                            </h2>
                            </div>

                            <div class="grid grid-cols-1 gap-5 mt-8 sm:mt-12 sm:grid-cols-2 xl:grid-cols-3 sm:gap-8 xl:gap-12">
                            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200 " >
                                <div class="p-6 lg:px-10 lg:py-8">
                                <div class="flex items-center justify-start space-x-8">
                                    <svg
                                    class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    stroke="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        stroke-width="1"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    </svg>
                                    <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                                    <div @click="toggleOption('Status', 'Functional','1')" >
                                    <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                        <a href="#" title="">
                                        Fully functional
                                        <span class="absolute inset-0" aria-hiddenx="true"></span>
                                        </a>
                                    </h3>
                                    <p class="mt-2 text-sm font-medium text-gray-500">
                                        983 Available Products
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                                <div class="p-6 lg:px-10 lg:py-8">
                                <div class="flex items-center justify-start space-x-8">
                                    <svg
                                    class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    stroke="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
                                        stroke-width="1"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    </svg>
                                    <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                                    <div @click="toggleOption('Status', 'Repair','1')">
                                    <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                        <a href="#" title="">
                                        Repair Stock
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        </a>
                                    </h3>
                                    <p class="mt-2 text-sm font-medium text-gray-500">
                                        142 Available Products
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
                                <div class="p-6 lg:px-10 lg:py-8">
                                <div class="flex items-center justify-start space-x-8">
                                    <svg
                                    class="flex-shrink-0 w-10 h-10 text-gray-600 md:w-12 md:h-12"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    stroke="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                        stroke-width="1"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    </svg>
                                    <div class="flex-shrink-0 w-px h-12 bg-gray-200"></div>
                                    <div @click="toggleOption('Status', 'Parts','1')">
                                    <h3 class="text-sm font-bold text-gray-900 sm:text-base lg:text-lg">
                                        <a href="#" title="">
                                        For Parts & DOA
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        </a>
                                    </h3>
                                    <p class="mt-2 text-sm font-medium text-gray-500">
                                        476 Available Products
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
 -->

          <div class="flex gap-4 px-0 sm:px-2 md:px-6 lg:px-8">
            <!-- FILTERS -->
            <div
              class="inset-x-auto bg-gray-50 px-5 hidden md:inline-block flex"
            >
              <div class="flow-root mt-5">
                <div class="-my-6">
                  <div class="py-6">
                    <div class="space-y-6 text-sm">
                      <div class="flex w-100">
                        <div class="flex items-center h-5"></div>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="inline-flex items-center p-1 -m-1 text-sm text-gray-900 transition-all duration-200 focus:outline-none group"
                      @click="resetFilters"
                      v-if="showReset"
                    >
                      <svg
                        class="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      Reset All Filters
                    </button>
                  </div>
                  <div class=" " v-for="(options, header) in headers">
                    <template
                      v-if="header !== 'Average List Price' && header !== 'Damages' && header !== 'Quantity'"
                    >
                      <button
                        type="button"
                        class="flex py-4 space-y-4 items-center justify-between w-full p-1 -m-1 text-base font-base text-gray-900 transition-all duration-200 group focus:outline-none"
                        @click="showOptions[header] = !showOptions[header]"
                      >
                        {{ header }}
                        <svg
                          v-if="hasFilter(currentOptions[header])"
                          class="h-2.5 w-2.5 text-blue-500"
                          fill="currentColor"
                          viewBox="0 0 8 8"
                        >
                          <circle cx="4" cy="4" r="3" />
                        </svg>
                        <svg
                          class="w-5 h-5 text-gray-400 group-hover:text-gray-900"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            :d="
                            showOptions[header]
                              ? 'M5 15l7-7 7 7'
                              : 'M19 9l-7 7-7-7'
                          "
                          />
                        </svg>
                      </button>
                    </template>
                    <!-- FILTER OPTIONS -->
                    <div class="space-y-2" v-if="showOptions[header]">
                      <div class="flex w-100" v-for="option in options">
                        <div
                          v-if="option !== 'N/A'"
                          class="flex items-center h-5"
                          @click="toggleOption(header, option)"
                        >
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            class="w-3 h-3 text-gray-900 border-gray-300 rounded-sm focus:ring-gray-900"
                            v-model="currentOptions[header][option]"
                          />
                        </div>
                        <div class="ml-1 text-sm">
                          <button
                            v-if="option !== 'N/A'"
                            type="button"
                            class="inline items-left ml-1 -m-1 font-semibold text-gray-900 rounded-lg"
                            @click="toggleOption(header, option)"
                          >
                            {{ option }}
                            <span
                              v-if="getItemCount(header, option) > 0"
                              class="text-xs text-gray-500 font-normal"
                            >
                              ({{ getItemCount(header, option) }})</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grow mt-12 bg-white border border-gray-200 rounded-xl px-10 py-5"
              style="width: -webkit-fill-available"
            >
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white z-50 border border-gray-300 rounded-lg shadow-sm lg:order-2 2xl:order-3 md:order-last hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                @click="exportToCSV"
              >
                <svg
                  class="w-4 h-4 mr-1 -ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
                Export to CSV
              </button>
              <div class="flex flex-col mt-4 lg:mt-8">
                <div class="-mx-4 -my-6 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                    style="max-height: 70vh"
                  >
                    <table class="min-w-full lg:divide-y lg:divide-gray-200">
                      <thead class="hidden lg:table-header-group">
                        <tr>
                          <th
                            class="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0"
                            v-for="(options, header) in headers"
                            v-if="header !== 'Damages'"
                            @click="toggleSort(header)"
                          >
                            <div class="flex items-center">
                              {{ header }}
                              <svg
                                class="w-4 h-4 ml-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  :d="
                                    sortByColumn !== header
                                      ? 'M8 9l4-4 4 4m0 6l-4 4-4-4'
                                      : sortByAsc
                                      ? 'M19 9l-7 7-7-7'
                                      : 'M5 15l7-7 7 7'
                                  "
                                />
                              </svg>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="">
                        <template v-for="item in filteredItems">
                          <tr
                            :class="
                              item.Damages
                                ? 'border-b-0'
                                : 'border-b border-gray-200'
                            "
                          >
                            <td
                              class="hidden py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0"
                            >
                              {{ item['Manufacturer'] }}
                            </td>
                            <td
                              class="px-4 py-4 text-sm font-bold text-gray-900 whitespace-nowrap"
                            >
                              <div class="inline-flex items-center">
                                {{ item['Model'] }}
                                <template v-if="item['Capacity'] !== 'N/A'">
                                  {{ item['Capacity'] }}
                                </template>
                              </div>
                              <div class="space-y-1 lg:hidden pl-2">
                                <p class="text-sm font-medium text-gray-800">
                                  <!--{{ item['Capacity'] }},-->
                                  {{ item['Network'] }}
                                </p>
                                <p class="text-sm font-medium text-gray-800">
                                  {{ item['Grade'] }}
                                </p>
                              </div>
                            </td>
                            <td
                              class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap"
                            >
                              {{ item['Capacity'] }}
                            </td>
                            <td
                              class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap"
                            >
                              {{ item['Network'] }}
                            </td>
                            <td
                              class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap"
                            >
                              {{ item['Grade'] }}
                            </td>
                            <td
                              class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap"
                            >
                              <div class="inline-flex items-center">
                                <svg
                                  :class="getStatusClasses(item['Status'])"
                                  fill="currentColor"
                                  viewBox="0 0 8 8"
                                >
                                  <circle cx="4" cy="4" r="3" />
                                </svg>
                                {{
                                  item['Status'] === 'Available'
                                    ? 'Functional'
                                    : item['Status']
                                }}
                              </div>
                            </td>
                            <td
                              class="px-4 py-4 text-sm font-medium text-right text-gray-900 whitespace-nowrap"
                            >
                              <p class="font-bold">
                                {{ item['Quantity'] }}
                                <span class="font-normal"> Unit(s)</span>
                              </p>
                              <div class="mt-1 lg:hidden">
                                <p class="font-bold">
                                  {{
                                    item['Average List Price']
                                      ? formatNum(item['Average List Price'], {
                                          dp: 2,
                                          currency: '$',
                                        })
                                      : ''
                                  }}
                                </p>
                                <div
                                  class="inline-flex items-center justify-end mt-1"
                                >
                                  <svg
                                    :class="getStatusClasses(item['Status'])"
                                    fill="currentColor"
                                    viewBox="0 0 8 8"
                                  >
                                    <circle cx="4" cy="4" r="3" />
                                  </svg>
                                  {{
                                    item['Status'] === 'Available'
                                      ? 'Functional'
                                      : item['Status']
                                  }}
                                </div>
                              </div>
                            </td>
                            <td
                              class="hidden px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap"
                            >
                              {{
                                item['Average List Price']
                                  ? formatNum(item['Average List Price'], {
                                      dp: 2,
                                      currency: '$',
                                    })
                                  : ''
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="item.Damages"
                            class="border-b border-gray-200"
                          >
                            <td
                              class="px-4 pb-2"
                              :colspan="Object.keys(headers).length"
                            >
                              <p
                                class="inline text-left text-sm font-medium text-gray-700 mr-1"
                                v-for="damage in item.Damages.split(',').sort()"
                              >
                                <span :class="getDamageClasses(item.Status)">
                                  {{ damage }}
                                </span>
                              </p>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  data() {
    return {
      wholecellJSONs: [],
      currentOptions: {
      Status: {
        "Functional": true,
        "Accessory": false,
        "Parts": false,
        "Repair": false
      }
    },
      showOptions: {},
      showFilters: false,
      showReset: false,
      sortByColumn: '',
      sortByAsc: false,
      messageButton: 'Show filters',
    };
  },
  firestore() {
    this.$binding('wholecellJSONs', cdsDB.getCol('wholecellJSON'));
  },
  computed: {
    filteredItems() {
      // Only filter if at least one item is checked.
      let includedFilters = Object.entries(this.currentOptions)
        .filter(([dimension, values]) => {
          return Object.values(values).some((val) => val);
        })
        .map(([dimension, values]) => dimension);
      // sort.
      return this.items
        .filter((item) => {
          return (
            Object.entries(item).every(([key, val]) => {
              if (!includedFilters.includes(key)) return true;
              if ('Average List Price' === key) return true;
              if ('Damages' === key) {
                if (!val) return this.currentOptions[key]['No Damage'];
                return val.split(',').some((d) => {
                  return (
                    this.currentOptions[key] &&
                    this.currentOptions[key][d.trim()]
                  );
                });
              }

              return this.currentOptions[key] && this.currentOptions[key][val];
            }) && !Object.values(item).every((r) => !r)
          );
        })
        .sort((a, b) => {
          if (['Quantity', 'Average List Price'].includes(this.sortByColumn)) {
            var textA = parseFloat(a[this.sortByColumn] || 0);
            var textB = parseFloat(b[this.sortByColumn] || 0);
          } else {
            var textA = a[this.sortByColumn].toUpperCase();
            var textB = b[this.sortByColumn].toUpperCase();
          }
          if (this.sortByAsc) return textA < textB ? -1 : textA > textB ? 1 : 0;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
    },
    items() {
      if (this.wholecellJSONs.length === 0) return [];
      return JSON.parse(this.wholecellJSONs[0].data);
    },
    headers() {
      return this.items.reduce((x, item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (!x[key]) x[key] = new Set();
          if (value !== '') {
            if (key === 'Damages') {
              x[key].add('No Damage');
              item['Damages'] = value
                .split(',')
                .map((d) => d.trim())
                .join(',');
              value.split(',').forEach((d) => x[key].add(d));
            } else x[key].add(value);
          }
        });
        return x;
      }, {});
    },
  },
  watch: {
    headers() {
      this.toggleSort(Object.keys(this.headers)[0]);
      this.resetFilters();
      this.showOptions = Object.keys(this.headers).reduce((x, key) => {
        if (!x[key]) x[key] = false;
        return x;
      }, {});
    },
  },
  methods: {
    hideFilters() {
      this.showFilters = !this.showFilters;
      if (this.showFilters) {
        this.messageButton = 'Hide filters';
      } else {
        this.messageButton = 'Show filters';
      }
    },
    resetFilters() {
  this.showReset = false;
  this.currentOptions = Object.entries(this.headers).reduce(
    (x, [key, values]) => {
      if (!x[key]) x[key] = {};
      [...values].forEach((value) => {
        if (value !== '') x[key][value] = false;
      });
      return x;
    },
    {}
  );
  this.currentOptions['Status']['Functional'] = true;
},
    hasFilter(options) {
      return [...new Set(Object.values(options))].length > 1;
    },
    toggleOption(header, option, onlyThis) {
      this.showReset = true;
      // Normal toggle
      if (!onlyThis)
        return (this.currentOptions[header][option] =
          !this.currentOptions[header][option]);

      // Filter by this option only.
      let options = Object.keys(this.currentOptions[header]);
      this.currentOptions[header][option] = false;
      // Unfilter when only this option was already selected.
      if (options.every((opt) => !this.currentOptions[header][opt])) {
        return options.forEach(
          (opt) => (this.currentOptions[header][opt] = true)
        );
      }
      options.forEach((opt) => (this.currentOptions[header][opt] = false));
      this.currentOptions[header][option] = true;
    },
    toggleSort(header) {
      // Flip direction
      if (this.sortByColumn === header)
        return (this.sortByAsc = !this.sortByAsc);

      // Change column
      this.sortByAsc = true;
      this.sortByColumn = header;
    },
    getItemCount(header, option) {
      return this.filteredItems.filter((it) => {
        if (header === 'Damages') {
          if (option === 'No Damage') return it[header] === '';
          return it[header].split(',').includes(option);
        }
        return it[header] === option;
      }).length;
    },
    getStatusClasses(status) {
      let classes = 'mr-1.5 h-2.5 w-2.5';
      switch (status) {
        case 'Accessory':
          {
            classes += ' text-purple-500';
          }
          break;
        case 'Parts':
          {
            classes += ' text-red-500';
          }
          break;
        case 'Repair':
          {
            classes += ' text-yellow-500';
          }
          break;
        case 'Available':
          {
            classes += ' text-green-500';
          }
          break;
        case 'Functional':
          {
            classes += ' text-green-500';
          }
          break;
      }
      return classes;
    },
    getDamageClasses(status) {
      let classes =
        'text-xxs font-semibold rounded-full inline-flex items-center px-2.5 py-0.5';
      if (status === 'Available' || status === 'Functional')
        return (
          classes + ' text-yellow-600 bg-yellow-50 border border-yellow-300'
        );
      return classes + ' text-red-600 bg-red-50 border border-red-300';
    },
    exportToCSV() {
      let headers = Object.keys(this.headers);
      let csvString =
        headers
          .map((header) => (header || '').toString())
          .map((v) => v.replaceAll('"', '""'))
          .map((v) => `"${v}"`)
          .join(',') +
        '\r\n' +
        this.filteredItems
          .map((item) =>
            headers
              .map((header) => {
                switch (header) {
                  case 'Average List Price':
                    {
                      return item['Average List Price']
                        ? this.formatNum(item['Average List Price'], {
                            dp: 2,
                            currency: '$',
                          })
                        : '';
                    }
                    break;
                  case 'Status':
                    {
                      return item['Status'] === 'Available'
                        ? 'Functional'
                        : item['Status'];
                    }
                    break;
                }
                return (item[header] || '').toString();
              })
              .map((v) => v.replaceAll('"', '""'))
              .map((v) => `"${v}"`)
              .join(',')
          )
          .join('\r\n');
      let blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' });
      saveAs(
        blob,
        'cellback-inventory-' + this.formatDate(new Date().getTime()) + '.csv'
      );
    },
  },
};
</script>
<style>
.moveleft {
  transform: translate(-300px, -20%);
}
</style>
