//======================================================//
//                                                      //
//                        IMPORT                        //
//                                                      //
//======================================================//
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";


//======================================================//
//                                                      //
//                         INIT                         //
//                                                      //
//======================================================//
const firebaseApp = firebase.initializeApp({
	"apiKey": "AIzaSyBGG_whSQODaKaQ-t4fy556FA7d1OPlIME",
	"authDomain": "cellback-f7459.firebaseapp.com",
	"databaseURL": "9.firebaseapp.com",
	"projectId": "cellback-f7459",
	"storageBucket": "cellback-f7459.appspot.com",
	"messagingSenderId": "870079152082",
	"appId": "1:870079152082:web:4414dbb18b738113ba120b",
	"measurementId": "G-MEFQR4EVQN"
})


//----------------------- UTILS ------------------------//
const db = firebaseApp.firestore();
let fieldVal = firebase.firestore.FieldValue;

const auth = firebaseApp.auth();
const storage = firebaseApp.storage();



//======================================================//
//                                                      //
//                       DATABASE                       //
//                                                      //
//======================================================//
//----------------------- CREATE -----------------------//
function setDoc(path, doc, docID, notiStart, notiDone){
	if (notiStart){
		cdsNotify.toast(notiStart);
	}
	let currentTime = new Date().getTime();
	let sequence = 1000;
	if (doc.constructor === {}.constructor){
		doc.ID = doc.ID || docID || db.collection(path).doc().id;
		doc["Date Created"] = currentTime;
		doc["Date Updated"] = currentTime;
		doc["cdsSortIndex"] = sequence;	
		return db.collection(path).doc(doc.ID).set(doc).then(() =>{
			if (notiDone){
				cdsNotify.toast(notiDone);
			}
			console.log("Setting " + docID + " at " + path + " was succcessful.");
		}).catch(err=>{
			console.log(err.message);
		})
	}
	else if (doc.constructor === [].constructor){
		let batch = db.batch();
		doc.forEach(d=>{
			d.ID = d.ID || db.collection(path).doc().id;
			d["Date Created"] = currentTime;
			d["Date Updated"] = currentTime;
			d["cdsSortIndex"] = sequence;
			sequence++;
			batch.set(db.collection(path).doc(d.ID), d);
		})
		return batch.commit().then(() =>{
			if (notiDone){
				cdsNotify.toast(notiDone);
			}
			console.log("Setting " + docID + " at " + path + " was succcessful.");
		}).catch(err=>{
			console.log(err.message);
		})
	}
};
//------------------------ READ ------------------------//
function getDoc(path, docID){
	return db.collection(path).doc(docID);
}
function getCol(path){
	return db.collection(path);
}
//----------------------- UPDATE -----------------------//
function updateDoc(path, doc, docID, notiStart, notiDone){
	if (notiStart){
		cdsNotify.toast(notiStart);
	}
	let currentTime = new Date().getTime();
	if (doc.constructor === {}.constructor){
		doc["Date Updated"] = currentTime;
		docID = docID || doc.ID;
		return db.collection(path).doc(docID).update(doc).then(() =>{
			if (notiDone){
				cdsNotify.toast(notiDone);
			}
			console.log("Updating " + docID + " at " + path + " succcessful.");
		}).catch(err=>{
			console.log(err.message);
		})
	}
	else if (doc.constructor === [].constructor){
		let batch = db.batch();
		doc.forEach(d=>{
			d.ID = d.ID || db.collection(path).doc().id;
			d["Date Updated"] = currentTime;
			batch.update(db.collection(path).doc(d.ID), d);
		})
		return batch.commit().then(() =>{
			if (notiDone){
				cdsNotify.toast(notiDone);
			}
			console.log("Updating " + docID + " at " + path + " was succcessful.");
		}).catch(err=>{
			console.log(err.message);
		})
	}

};
//----------------------- DELETE -----------------------//
function deleteDoc(path, docID, notiStart, notiDone){
	if (notiStart != undefined){
		cdsNotify.toast(notiStart);
	}
	if (docID.constructor === [].constructor){
		let batch = db.batch();
		if (path.constructor === [].constructor){

		}
		docID.forEach(d=>{
			batch.delete(db.collection(path).doc(d));
		})
		return batch.commit().then(() =>{
			if (notiDone){
				cdsNotify.toast(notiDone);
			}
			console.log("Deletion succcessful.");
		}).catch(err=>{
			console.log(err.message);
		})
	}
	else if (typeof(docID) === "string"){
		return db.collection(path).doc(docID).delete().then(() =>{
			if (notiDone != undefined){
				cdsNotify.toast(notiDone);
			}
			console.log("Deleting " + docID + " at " + path + " succcessful.");
		}).catch(err=>{
			console.log(err.message);
		})
	}
}
//--------------------- ID HANDLING --------------------//
function genID(){
	return db.collection("cdsBruv").doc().id;
}
//======================================================//
//                                                      //
//                         AUTH                         //
//                                                      //
//======================================================//
//----------------------- TOKEN ------------------------//
async function getToken(){
	return await cdsDB.getUser().getIdToken();
}
//----------------------- EMAIL ------------------------//
async function emailLogin(email, pw){
	return new Promise((resolve, reject)=>{
		auth.signInWithEmailAndPassword(email, pw).then(user => {
			console.log("logged in")
			return resolve({
				success: true,
				user: user,
				msg: ""
			});
		}).catch(e =>{
			return resolve({
				success: false,
				user: null,
				msg: e.message
			});
		})
	})
}
async function emailRegister(email, pw){
	return new Promise((resolve, reject)=>{
		auth.createUserWithEmailAndPassword(email, pw).then(user => {
			console.log("registered")
			return resolve({
				success: true,
				user: user,
				msg: ""
			});
		}).catch(e =>{
			return resolve({
				success: false,
				user: null,
				msg: e.message
			});
		})
	})
}
async function updatePassword(email, pw, newPW){
	return new Promise((resolve, reject)=>{
		auth.signInWithEmailAndPassword(email, pw).then(user=>{
			return auth.currentUser.updatePassword(newPW).then(r=> {
				console.log("Password updated")
				return resolve({
					success: true,
					msg: ""
				})
			}).catch(e =>{
				return resolve({
					success: false,
					user: null,
					msg: e.message
				})
			})
		}).catch(e =>{
			return resolve({
				success: false,
				user: null,
				msg: e.message
			})
		})
	})
}
async function updateEmail(email, pw, Email){
	return new Promise((resolve, reject)=>{
		auth.signInWithEmailAndPassword(email, pw).then(user=>{
			return auth.currentUser.updateEmail(Email).then(r=> {
				console.log("Password updated")
				return resolve({
					success: true,
					msg: ""
				})
			}).catch(e =>{
				return resolve({
					success: false,
					user: null,
					msg: e.message
				})
			})
		}).catch(e =>{
			return resolve({
				success: false,
				user: null,
				msg: e.message
			})
		})
	})
}
async function resetPassword(email){
	return new Promise((resolve, reject)=>{
		auth.sendPasswordResetEmail(email).then(r=>{
			resolve({
				success: true,
				msg: "Password reset email sent."
			})
		}).catch(e=>{
			resolve({
				success: false,
				msg: e.message
			})
		})
	})
}
//----------------------- PHONE ------------------------//
let phoneVerifier;
let phoneVerifyID;
function setupPhoneVerifier({
	expiredCallback
}){
	let container = document.getElementById("recaptcha-container");
	if (!container){
		let div = document.createElement('div');
		div.setAttribute("id", "recaptcha-container");
		document.body.appendChild(div); 
	}
	if (!phoneVerifier){
		phoneVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			'size': 'invisible',
			'callback': function(response) {
			},
			'expired-callback': function() {
				expiredCallback();
			}
		});
	}
}
function disposePhoneVerifier(){
	phoneVerifier = null;
	document.getElementById("recaptcha-container").remove();
}
async function phoneLogin({
	phoneNum,
	callback,
	expiredCallback,
	errorCallback
}){
	phoneNum = "+" + (phoneNum[0] == "0" ? "6": "") + phoneNum;
	try{
		setupPhoneVerifier({
			expiredCallback,
		});
		auth.signInWithPhoneNumber("+" + phoneNum, phoneVerifier).then(resOTP =>{
			window.confirmationResult = resOTP;
			callback();
		}).catch(e =>{
			errorCallback(e);
		})
	}catch(e){
		errorCallback(e);
	}
}
async function phoneOTP({otp, callback, errorCallback}){
	window.confirmationResult.confirm(otp).then(() =>{
		disposePhoneVerifier();
		callback();
	}).catch(e=>{
		errorCallback(e);
	})
}
async function phoneChange({
	phoneNum,
	callback,
	expiredCallback,
	errorCallback
}){
	try{
		phoneNum = "+" + (phoneNum[0] == "0" ? "6": "") + phoneNum;
		var provider = new firebase.auth.PhoneAuthProvider();
		setupPhoneVerifier({
			expiredCallback,
		});
		provider.verifyPhoneNumber(phoneNum, phoneVerifier).then(function (verificationId) {
			phoneVerifyID = verificationId;
			return callback(verificationId);
		}).catch((e) => {
			errorCallback(e);
		});
	}catch(e){
		errorCallback(e);
	}
}
async function phoneChangeOTP({otp, callback, errorCallback}){
	try{
		let phoneCredential = firebase.auth.PhoneAuthProvider.credential(phoneVerifyID, otp);
		console.log(0);
		auth.currentUser.updatePhoneNumber(phoneCredential).catch(e=>{
			errorCallback(e);
		});
		console.log(1);
		callback();
		disposePhoneVerifier();
	}catch(e){
		errorCallback(e);
	}
}
//------------------- SOCIAL MEDIA ---------------------//
async function socialLogin(type){
	type = type.replace(/ /g, "").toUpperCase();
	if (["GOOGLE", "FACEBOOK", "TWITTER"].includes(type)){
		let provider;
		switch(type){
			case "GOOGLE":
				//
				provider = new firebase.auth.GoogleAuthProvider();
				break;
			//
			case "FACEBOOK":
				//
				provider = new firebase.auth.FacebookAuthProvider();
				break;
			//
			case "TWITTER":
				//
				provider = new firebase.auth.TwitterAuthProvider();
				break;
			//
		}
		return new Promise((resolve, reject)=>{
			auth.signInWithRedirect(provider).then(r=>{
				return resolve({
					success: true,
					user: r
				})
			}).catch(e=>{
				return resolve({
					success: false,
					msg: e.message
				})
			})
		})
	}
	else{
		return {
			success: false,
			msg: "Only google, faceboook or twitter are supported."
		}
	}
}
//----------------------- USER -------------------------//
function getUser(){
	return auth.currentUser;
}
//---------------------- LOGOUT ------------------------//
function logOut(){
	return auth.signOut()
}
//======================================================//
//                                                      //
//                       STORAGE                        //
//                                                      //
//======================================================//
//----------------------- UPLOAD -----------------------//
async function uploadStorage(path, file){
	return new Promise((resolve, reject)=>{
		storage.ref(path).put(file).then(async r=>{
			r.ref.getDownloadURL().then(url=>{
				resolve({
					success: true,
					url: url
				})
			}).catch(e=>{
				resolve({
					success: false,
					msg: e.message
				})
			})
		}).catch(e=>{
			resolve({
				success: false,
				msg: e.message
			})
		})
	})
}
//----------------------- DELETE -----------------------//
async function deleteStorage(path){
	return storage.ref(path).delete();
}


//======================================================//
//                                                      //
//                        EXPORT                        //
//                                                      //
//======================================================//
export default{
	// DATABASE
	db,
	setDoc,
	updateDoc,
	deleteDoc,
	getDoc,
	getCol,
	genID,
	fieldVal,

	// AUTH
	auth,
	getUser: getUser,
	emailLogin,
	emailRegister,
	updateEmail,
	updatePassword,
	resetPassword,
	socialLogin,
	logOut,
	getToken,

	phoneLogin,
	phoneOTP,
	phoneChange,
	phoneChangeOTP,

	// STORAGE
	storage,
	uploadStorage,
	deleteStorage,

}

