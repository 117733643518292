import cdsDate from "./dateManager.js";

//------------------------- INIT -------------------------//
var defVals = {
	"text"(){
		return "";
	},
	"email"(){
		return "";
	},
	"choice"(){
		return "";
	},
	"tel"(){
		return "";
	},
	"password"(){
		return "";
	},
	"date"(Compulsory){
		if (Compulsory){
			return cdsDate.displayCurrentDate({sequence: "y-m-d"});
		}
		else{
			return "";
		}
	},
	"month"(Compulsory){
		if (Compulsory){
			return cdsDate.displayCurrentDate({sequence: "y-m"});
		}
		else{
			return "";
		}
	},
	"year"(Compulsory){
		if (Compulsory){
			return cdsDate.displayCurrentDate({sequence: "y"});
		}
		else{
			return "";
		}
	},
	"time"(Compulsory){
		if (Compulsory){
			return cdsDate.displayCurrentTime({sequence: "hh:mm:ss", hourFormat: 24});
		}
		else{
			return "";
		}
	},
	"number"(){
		return "";
	},
	"boolean"(){
		return true;
	},
	"JSON"(){
		return new Object();
	},
	"Array"(){
		return new Array();
	},
	"multi-choice"(){
		return new Array();
	},
}
function filterName(name){
	var nameNow, c;
	nameNow = "";
	for (var i = 0; i < name.length; i++){
		c = name[i];
		if ((c >= '0' && c <= '9') || (c.toLowerCase().match(/[a-z]/i))){
			nameNow += c;
		}
	}
	return nameNow;
}

function init(template, vue, fullName){
	var fullName = fullName || "";
	var output;
	var complex = ["JSON", "Array"];
	var initValue = function(template, vue, fullName){
		var output
		if (template.Type[0] === "Array"){
			output = [];
		}
		else if (template.Type[0] === "JSON"){
			output = init(template.children, vue, fullName);
		}
		else if (template.Type[0] === "choice"){
			output = "";
			if (template.Settings.DefaultValue != null){
				output = ((vue || {})[fullName + "Choices"] || [])[parseInt(template.Settings.DefaultValue)] || "";
			}
			else{
				output = "";
			}
		}
		else if (template.Type[0] === "date"){
			if (template.Settings.DefaultValue != null){
				var date = new Date();
				date.setDate(date.getDate() + template.Settings.DefaultValue);
				output = cdsDate.displayDate(date.getTime(), {sequence: "y-m-d"});
			}
			else{
				output = defVals["date"](template.Compulsory);
			}
		}
		else if (template.Type[0] === "month"){
			if (template.Settings.DefaultValue != null){
				var date = new Date();
				date.setDate(date.getDate() + template.Settings.DefaultValue);
				output = cdsDate.displayDate(date.getTime(), {sequence: "y-m"});
			}
			else{
				output = defVals["month"](template.Compulsory);
			}
		}
		else if (template.Type[0] === "year"){
			if (template.Settings.DefaultValue != null){
				var date = new Date();
				date.setDate(date.getDate() + template.Settings.DefaultValue);
				output = cdsDate.displayDate(date.getTime(), {sequence: "y"});
			}
			else{
				output = defVals["year"](template.Compulsory);
			}
		}
		else if (template.Type[0] === "time"){
			if (template.Settings.DefaultValue != ""){
				output = template.Settings.DefaultValue;
			}
			else{
				output = defVals["time"](template.Compulsory);
			}
		}
		else if (template.Type[0] === "date-time"){
			output = {};
			if (template.Settings.date.DefaultValue != null){
				var date = new Date();
				date.setDate(date.getDate() + template.Settings.date.DefaultValue);
				output.date = cdsDate.displayDate(date.getTime());
			}
			else{
				output.date = defVals["date"](template.Compulsory);
			}
			if (template.Settings.time.DefaultValue != ""){
				output.time = template.Settings.time.DefaultValue;
			}
			else{
				output.time = defVals["time"](template.Compulsory);
			}
		}
		else if (typeof(template.Settings.DefaultValue) === "boolean"){
			output = template.Settings.DefaultValue;
			if (template.Settings.mustTrue){
				output = false;
			}
		}
		else if (template.Settings.DefaultValue != null){
			output = template.Settings.DefaultValue.toString();
		}
		else{
			output = defVals[template.Type[0]](template.Compulsory);
		}

		return output;
	}
	if (template.constructor === [].constructor){
		output = {};
		var nameNow, c;
		template.forEach(d=>{
			nameNow = filterName(d.Name);
			output[d.Name] = initValue(d, vue, fullName + nameNow);
			if (d.Settings.Repeat){
				output["Repeat " + d.Name] = initValue(d, vue, fullName + nameNow);
			}
		})
	}
	else{
		output = initValue(template, vue, fullName);
	}
	return output;
}


//------------------------- CHECK -------------------------//
var checkFun = {
	"text"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.minLengthErrorMsg || "Value cannot be empty.",
					output: input
				}
			}
		}
		if (template.Compulsory || input !== ""){
			if (template.Settings.minLength != null && input.length < template.Settings.minLength){
				return{
					error: true,
					errorMsg: template.Settings.minLengthErrorMsg || "Please input at least " + (template.Settings.minLength) + " characters.",
					output: input
				}
			}
			else if (template.Settings.maxLength != null && input.length > template.Settings.maxLength){
				return{
					error: true,
					errorMsg: template.Settings.maxLengthErrorMsg || "Only " + template.Settings.max + " characters are allowed." ,
					output: input
				}
			}
			else if ( template.Settings.regexp && !input.match(new RegExp(template.Settings.regexp))){
				return{
					error: true,
					errorMsg:  template.Settings.regexpErrorMsg || "Value is formatted incorrectly.",
					output: input
				}
			}
		}
		return{
			error: false,
			errorMsg: "",
			output: input
		}
	},
	"choice"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please pick a value.",
					output: input
				}
			}
		}
		return{
			error: false,
			errorMsg: "",
			output: [input]
		}
	},
	"email"(input, template, input2){
		var input = input || "";
		input = input.replace(/ /g, "");
		var errorMsg = "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: "Please input an email.",
					output: ""
				}
			}
		}
		if (input != ""){
			if ( template.Settings.regexp && !input.match(new RegExp(template.Settings.regexp))){
				return{
					error: true,
					errorMsg:  template.Settings.regexpErrorMsg || "Email is formatted incorrectly.",
					output: input
				}
			}
			return{
				error: false,
				errorMsg: "",
				output: input
			}
		}
		else{
			return {
				error: false,
				output: "",
				errorMsg: ""
			}
		}
	},
	"tel"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please input a valid phone number.",
					output: input
				}
			}
		}
		var output = "";
		var error = false;
		var errorMsg = "";
		if (input != ""){
			if (template.Settings.minLength != null && input.length < template.Settings.minLength){
				error = true;
				errorMsg += " " +  template.Settings.minLengthErrorMsg || (" Phone number must be at least" + template.Settings.minLength + " digits long.");
			}
			else if (template.Settings.maxLength != null && input.length > template.Settings.maxLength){
				error = true;
				errorMsg += " " +  template.Settings.maxLengthErrorMsg || (" Phone number cannot exceed " + template.Settings.maxLength + " digits.");
			}
			else if ( template.Settings.regexp && !input.match(new RegExp(template.Settings.regexp))){
				return{
					error: true,
					errorMsg: template.Settings.regexpErrorMsg || "Phone number is formatted incorrectly",
					output: input
				}
			}
		}
		return {
			error: error,
			errorMsg: errorMsg,
			output: input
		}
	},
	"password"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please input a password.",
					output: input
				}
			}
		}
		var output = "";
		var error = false;
		var errorMsg = "";
		if (template.Compulsory || input != ""){
			var checkArray = input.split("");
			if (template.Settings.minLength != null && input.length < template.Settings.minLength){
				errorMsg =  template.Settings.minLengthErrorMsg || (" Password must be at least" + template.Settings.minLength + " characters long.");
			}
			else if ( template.Settings.regexp && !input.match(new RegExp(template.Settings.regexp))){
				return{
					error: true,
					errorMsg: template.Settings.regexpErrorMsg || "Password does not pass the security test.",
					output: input
				}
			}
		}
		return{
			error: error, 
			errorMsg: errorMsg,
			output: input
		}
	},
	"date"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please input a date.",
					output: input
				}
			}
		}
		if (input != ""){
			var inputCopy = input;
			inputCopy = inputCopy.replace(/ /g, "/");
			inputCopy = inputCopy.replace(/-/g, "/");
			var output = cdsDate.getTime(inputCopy + " 00:00:01");
			var currentDate = new Date();
			currentDate.setHours(0);
			currentDate.setMinutes(0);
			currentDate.setSeconds(0);
			if (isNaN(output)){
				return{
					error: false,
					errorMsg: "Input is not a date.",
					output: 0
				}
			}
			var dDays = Math.round((output - currentDate)/86400000);
			if (template.Settings.min != null &&  dDays < template.Settings.min){
				return {
					error: true,
					errorMsg: template.Settings.minErrorMsg || "Date is before minimum date allowed.",
					output: output
				}
			}
			else if (template.Settings.max != null &&  dDays > template.Settings.max){
				return {
					error: true,
					errorMsg: template.Settings.maxErrorMsg || "Date is after maximum date allowed.",
					output: output
				}
			}
			return{
				error: false,
				errorMsg: "",
				output: output
			}
		}
		return{
			error: false,
			errorMsg: "",
			output: 0
		}
	},
	"month"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please input a date.",
					output: input
				}
			}
		}
		if (input != ""){
			var inputCopy = input;
			inputCopy = inputCopy.replace(/ /g, "/");
			inputCopy = inputCopy.replace(/-/g, "/");
			var output = cdsDate.getTime(inputCopy + "-01 00:00:01");
			var outputDate = new Date(output);
			var currentDate = new Date();
			currentDate.setDate(1);
			currentDate.setHours(0);
			currentDate.setMinutes(0);
			currentDate.setSeconds(0);
			if (isNaN(output)){
				return{
					error: false,
					errorMsg: "Input is not a date.",
					output: 0
				}
			}
			let nMonths = (outputDate.getFullYear() - currentDate.getFullYear()) * 12;
			nMonths -= currentDate.getMonth();
			nMonths += outputDate.getMonth();
			if (template.Settings.min != null &&  nMonths < template.Settings.min){
				return {
					error: true,
					errorMsg: template.Settings.minErrorMsg || "Date is before minimum date allowed.",
					output: output
				}
			}
			else if (template.Settings.max != null &&  nMonths > template.Settings.max){
				return {
					error: true,
					errorMsg: template.Settings.maxErrorMsg || "Date is after maximum date allowed.",
					output: output
				}
			}
			return{
				error: false,
				errorMsg: "",
				output: output
			}
		}
		return{
			error: false,
			errorMsg: "",
			output: 0
		}
	},
	"year"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please input a date.",
					output: input
				}
			}
		}
		if (input != ""){
			var inputCopy = input;
			inputCopy = inputCopy.replace(/ /g, "/");
			inputCopy = inputCopy.replace(/-/g, "/");
			var output = cdsDate.getTime(inputCopy + "-01-01 00:00:01");
			var outputDate = new Date(output);
			var currentDate = new Date();
			currentDate.setMonth(0);
			currentDate.setDate(1);
			currentDate.setHours(0);
			currentDate.setMinutes(0);
			currentDate.setSeconds(0);
			if (isNaN(output)){
				return{
					error: false,
					errorMsg: "Input is not a year.",
					output: 0
				}
			}
			let nYears = (outputDate.getFullYear() - currentDate.getFullYear());
			if (template.Settings.min != null &&  nYears < template.Settings.min){
				return {
					error: true,
					errorMsg: template.Settings.minErrorMsg || "Year is before minimum year allowed.",
					output: output
				}
			}
			else if (template.Settings.max != null &&  nYears > template.Settings.max){
				return {
					error: true,
					errorMsg: template.Settings.maxErrorMsg || "Year is after maximum year allowed.",
					output: output
				}
			}
			return{
				error: false,
				errorMsg: "",
				output: output
			}
		}
		return{
			error: false,
			errorMsg: "",
			output: 0
		}
	},
	"time"(input, template){
		var input = input || "";
		if (typeof(input) === "string"){
			var error = false;
			var inputArr = input.split("");
			switch(input.length){
				case 5:
					//
					error = false;
					if (inputArr[2] !== ":"){
						error = true;
					}
					for (var i = 0; i<2; i++){
						if (isNaN(inputArr[i])){
							error = true;
						}
					}
					for (var i = 3; i<5; i++){
						if (isNaN(inputArr[i])){
							error = true;
						}
					}
					break;
				//
				case 8:
					//
					error = false;
					if (inputArr[2] !== ":"){
						error = true;
					}
					if (inputArr[5] !== ":"){
						error = true;
					}
					for (var i = 0; i<2; i++){
						if (isNaN(inputArr[i])){
							error = true;
						}
					}
					for (var i = 3; i<5; i++){
						if (isNaN(inputArr[i])){
							error = true;
						}
					}
					for (var i = 6; i<8; i++){
						if (isNaN(inputArr[i])){
							error = true;
						}
					}
					error = false;
					break;
				//
				case 0:
					//
					if (!template.Compulsory){
						return {
							error: false,
							errorMsg: "",
							output: ""
						}	
					}
					else{
						return{
							error: true,
							errorMsg: "Please pick a time",
							output: ""
						}
					}
				//
			}
			if (error){
				return{
					error: true,
					errorMsg: "Invalid time.",
					output: ""
				}
			}
			else{
				var currentTime = cdsDate.timeToSeconds(input);

				if (template.Settings.min != null && currentTime < cdsDate.timeToSeconds(template.Settings.min)){
					return {
						error: true,
						errorMsg: template.Settings.minErrorMsg || "Time is before minimum allowed time range.",
						output: input
					}
				}
				else if (template.Settings.max != null && currentTime > cdsDate.timeToSeconds(template.Settings.max)){
					return {
						error: true,
						errorMsg: template.Settings.maxErrorMsg || "Time is after maximum allowed time range.",
						output: input
					}

				}

				return{
					error: false,
					errorMsg: "",
					output: input
				}
			}
		}
		else{
			return{
				error: true,
				errorMsg: "Input is not a string",
				output: ""
			}	
		}
	},
	"date-time"(input, template){
		var input = input || "";
		if (input.constructor === {}.constructor){
			var inputOther = cdsCopier.copy(input);
		}
		else{
			var inputOther = editMain(cdsDate.getTime(input.replace(/-/g, "/")), template);
			input = inputOther
		}
		var date = checkFun["date"](inputOther.date, {
			Compulsory: template.Compulsory,
			Settings: template.Settings.date
		});
		var time = checkFun["time"](inputOther.time, {
			Compulsory: template.Compulsory,
			Settings: template.Settings.time
		});
		if (date.error || time.error){
			return {
				error: true,
				output: 0,
				errorMsg:{
					date: date.errorMsg,
					time: time.errorMsg
				}
			}
		}
		else{
			return {
				error: false,
				output: cdsDate.getTime(input.date.replace(/-/g, "/") + " " + input.time),
				errorMsg: {
					date: "",
					time: ""
				}
			}
		}
	},
	"number"(input, template){
		var input = input || "";
		if (template.Compulsory){
			if (input === ""){
				return {
					error: true,
					errorMsg: template.Settings.errorMsg || "Please input a value.",
					output: input
				}
			}
		}
		else if (input ==""){
			return {
				error: false,
				errorMsg: "",
				output: null
			}
		}
		template.Settings["Decimal Places"] = template.Settings["Decimal Places"] || 12;
		var output = Math.round(parseFloat(input)*10**template.Settings["Decimal Places"])/10**template.Settings["Decimal Places"];
		if (template.Settings.min != null && output < template.Settings.min){
			return {
				error: true,
				errorMsg: template.Settings.minErrorMsg || "Value cannot be below " + template.Settings.min + ".",
				output: output
			}
		}
		else if (template.Settings.max != null && output > template.Settings.max){
			return {
				error: true,
				errorMsg: template.Settings.maxErrorMsg || "Value cannot be above " + template.Settings.max + ".",
				output: output
			}
		}
		return{
			error: false,
			errorMsg: "",
			output: Math.round(output*10**template.Settings["Decimal Places"])/10**template.Settings["Decimal Places"]
		}
	},
	"boolean"(input, template){
		if (input === undefined){
			input = false;
		}
		if (template.Compulsory && template.Settings.mustTrue && !input){
			return {
				error: true,
				errorMsg: template.Settings.mustTrueErrorMsg || "Please check the field.",
				output: false
			}
		}
		var output;
		if (input === "" || input === undefined){
			output = false;
		}
		else if (typeof(input) === "string" && (input.toUpperCase() === "TRUE"|| input.toUpperCase() === "ON")){
			output = true;
		}
		else if (typeof(input) === "boolean"){
			output = input;
		}
		else{
			output = false;
		}
		return {
			error: false,
			errorMsg: "",
			output: output
		}
	},
	"JSON"(input, template){
		return checkMain(input || {}, template.children);
	},
	"Array"(input, template){
		var input = input || [];
		if (template.Settings.minLength != null && input.length < template.Settings.minLength){
			return{
				error: true,
				errorMsg: template.Settings.minLengthErrorMsg || "Please choose at least " + (template.Settings.minLength) + " entries.",
				output: input
			}
		}
		else if (template.Settings.maxLength != null && input.length > template.Settings.maxLength){
			return{
				error: true,
				errorMsg: template.Settings.maxLengthErrorMsg || "Only " + (template.Settings.maxLength) + " entries are allowed.",
				output: input
			}
		}
		var output = [];
		var errorMsg = [];
		var error = false;
		var response;
		input.forEach(r=>{
			response = checkMain(r, template.children);
			output.push(response.output);
			errorMsg.push(response.errorMsg);
			error = error || response.error;

		})
		return {
			errorMsg,
			error,
			output
		}
	},
	"multi-choice"(input, template){
		if (template.Settings.minLength != null && input.length < template.Settings.minLength){
			return{
				error: true,
				errorMsg: template.Settings.minLengthErrorMsg || "Please choose at least " + (template.Settings.minLength) + " entries.",
				output: input
			}
		}
		else if (template.Settings.maxLength != null && input.length > template.Settings.maxLength){
			return{
				error: true,
				errorMsg: template.Settings.maxLengthErrorMsg || "Only " + (template.Settings.maxLength) + " entries are allowed.",
				output: input
			}
		}
		return {
			errorMsg: "",
			error: false,
			output: input
		}
	}
}
function checkMain(input, template){
	var output;
	var error = false;
	var errorMsg;
	if (template.constructor === [].constructor){	
		output = {};
		errorMsg = {};
		var response;
		var allInputFields = Object.keys(input);
		template.forEach(r=>{
			allInputFields.splice(allInputFields.indexOf(r.Name), 1);
			response = checkFun[r.Type[0]](input[r.Name] || "", r);
			output[r.Name] = response.output;
			errorMsg[r.Name] = response.errorMsg;
			error = error || response.error;
			if (r.Settings.Repeat){
				if (input[r.Name] != input["Repeat " + r.Name]){
					error = true;
					errorMsg["Repeat " + r.Name] = r.Settings.RepeatErrorMsg || r.Name + "do not match.";
				}
				else{
					errorMsg["Repeat " + r.Name] = "";
				}
				allInputFields.splice(allInputFields.indexOf("Repeat " + r.Name), 1);
				delete output["Repeat " + r.Name];
			}
		})
		allInputFields.forEach(r=>{
			output[r] = cdsCopier.copy(input[r]);
		})
	}
	else{
		return checkFun[template.Type[0]](input, template);
	}

	return {
		output,
		error,
		errorMsg
	};
}

function checkStructure(input, template){
	var output = init(template);
	var wrongStructure = false;

	if (template.constructor === [].constructor){
		var response;
		template.forEach(temp=>{
			if (input[temp.Name] === undefined){
				wrongStructure = true;
			}
			else if (temp.Type[0] === "Array"){
				output[temp.Name] = [];
				input[temp.Name].forEach(inp=>{
					response = checkStructure(inp, temp.children);
					output[temp.Name].push(response.output);					
					wrongStructure = wrongStructure || response.wrongStructure;	
				})
			}
			else if (temp.Type[0] === "JSON"){
				response = checkStructure(input[temp.Name], temp.children);
				output[temp.Name] = response.output;
				wrongStructure = wrongStructure || response.wrongStructure;	
			}
			else{
				output[temp.Name] = input[temp.Name];
			}
		})
	}
	else{
		if (template.Type[0] === "Array"){
			output = [];
			input.forEach(inp=>{
				response = checkStructure(inp, template.children);
				output.push(response.output);					
				wrongStructure = wrongStructure || response.wrongStructure;	
			})
		}
		else if (template.Type[0] === "JSON"){
			response = checkStructure(input, template.children);
			output = response.output;
			wrongStructure = wrongStructure || response.wrongStructure;	
		}
		else{
			output = input;
		}
	}
	return {
		output,
		wrongStructure
	};
}
function checkEmpty(input){
	var notEmpty = false;
	if (input.constructor === {}.constructor){
		for (var item in input){
			notEmpty = notEmpty || checkEmpty(input[item]);
		}
	}
	else if (input.constructor === [].constructor){
		input.forEach(r=>{
			notEmpty = notEmpty || checkEmpty(r);
		})
	}
	else{
		switch (typeof(input)){
			case "number":
				//
				notEmpty =  notEmpty || input.toString() !== "";
				break;
			//
			case "string":
				//
				notEmpty =  notEmpty || input.toString() !== "";
				break;
			//
			case "boolean":
				//
				notEmpty =  notEmpty || input.toString() !== "";
				break;
			//
			case "undefined":
				//
				break;
			//
		}
	}
	return notEmpty;
}

//------------------------- EDIT -------------------------//
var editFun = {
	"text"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return input || init(template, vue, fullName);
	},
	"choice"(input, template, vue, fullName){
		if ([undefined, null].includes(input) || [undefined, null].includes(input[0])){
			return init(template, vue, fullName);
		}
		if (input.constructor === [].constructor){
			return input[0] || init(template, vue, fullName);
		}
		else if (input.constructor === "".constructor){
			return input || init(template, vue, fullName);
		}
	},
	"email"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return input || init(template, vue, fullName);
	},
	"tel"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return input || init(template, vue, fullName);
	},
	"password"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return input || init(template, vue, fullName);
	},
	"date"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return cdsDate.displayDate(input, {sequence: "y-m-d"}) || init(template, vue, fullName);
	},
	"month"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return cdsDate.displayDate(input, {sequence: "y-m"}) || init(template, vue, fullName);
	},
	"year"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return cdsDate.displayDate(input, {sequence: "y"}) || init(template, vue, fullName);
	},
	"time"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return input || init(template, vue, fullName);
	},
	"date-time"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return {
			date: cdsDate.displayDate(input, {sequence: "y-m-d"}),
			time: cdsDate.displayTime(input, {sequence: "hh:mm:ss", hourFormat: 24})
		};
	},
	"number"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return (input).toString() ;
	},
	"boolean"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template, vue, fullName);
		}
		return input;
	},
	"JSON"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return init(template.children, vue, fullName);
		}
		return editMain(input, template.children, vue, fullName);
	},
	"Array"(input, template, vue, fullName){
		if ([undefined, null].includes(input)){
			return [];
		}
		var output = [];
		var response;
		input.forEach(r=>{
			output.push(editMain(r, template.children, vue, fullName));
		})
		return output
	},
	"multi-choice"(input, template, vue, fullName){
		return input || init(template, vue, fullName);
	},
}
function editMain(input, template, vue, fullName){
	var output;
	var fullName = fullName || "";
	if (template.constructor === [].constructor){	
		output = {};
		var response;
		var oriKeys = Object.keys(input);
		template.forEach(r=>{
			output[r.Name] = editFun[r.Type[0]](input[r.Name], r, vue, fullName + filterName(r.Name));
			if (oriKeys.includes(r.Name)) oriKeys.splice(oriKeys.indexOf(r.Name), 1);
		})
		oriKeys.forEach(r=>{
			output[r] = cdsCopier.copy(input[r]);
		})
	}
	else{
		output = editFun[template.Type[0]](input, template, vue, fullName);
	}

	return output;
}
function excelMain(input, template, vue, fullName){
	switch (template.Type[0]){ 
		case "multi-choice":
			//
			return input.join(", ");
			break;
		//
		case "choice":
			//
			return input[0];
			break;
		//
		case "date-time":
			//
			return cdsDate.displayDateTime(input, {}, {hourFormat: 24, sequence:"hh:mm:ss"});
			break;
		//
		default:
			//
			return editFun[template.Type[0]](input, template, vue, fullName);
			break;
		//
	}
}
function excelUpload(input, template){
	let error = false, errorMsg = new Object(), output = new Object();
	let response;
	template.filter(r=> !["Array", "JSON"].includes(r["Type"][0])).forEach(r=>{
		switch (r.Type[0]){ 
			case "choice":
			case "multi-choice":
				//
				response = checkFun[r.Type[0]]((input[r.Name] || "").split(",").map(elem=> elem.trim()), r);
				break;
			//
			default:
				//
				response = checkFun[r.Type[0]](input[r.Name], r);
				break;
			//
		}
		output[r.Name] = response.output;
		errorMsg[r.Name] = response.errorMsg;
		error = error || response.error;
	})
	return {
		error,
		errorMsg,
		output
	}
}


//------------------------ FILTER ------------------------//
let filterFun = {
	"text"(template, vue, fullName){
		return new String();
	},
	"choice"(template, vue, fullName){
		return vue[fullName + "Choices"].map(r=> r);
	},
	"email"(template, vue, fullName){
		return new String();
	},
	"tel"(template, vue, fullName){
		return new String();
	},
	"password"(template, vue, fullName){
		return new String();
	},
	"date"(template, vue, fullName){
		let dateNow = cdsDate.getTime();
		let Min = cdsDate.displayDate(dateNow + (template.Settings.min || 0) * 24*60*60*1000, {sequence: "y-m-d"});
		let Max = cdsDate.displayDate(dateNow + (template.Settings.max || 0) * 24*60*60*1000, {sequence: "y-m-d"});
		return {
			Min, 
			Max
		}
	},
	"month"(template, vue, fullName){
		let monthNow = new Date();
		monthNow.setMonth(monthNow.getMonth() + (template.Settings.min || 0));
		let Min = cdsDate.displayDate(monthNow, {sequence: "y-m"});
		monthNow = new Date();
		monthNow.setMonth(monthNow.getMonth() + (template.Settings.max || 0));
		let Max = cdsDate.displayDate(monthNow, {sequence: "y-m"});
		return {
			Min, 
			Max
		}
	},
	"year"(template, vue, fullName){
		let monthNow = new Date();
		monthNow.setFullYear(monthNow.getFullYear() + (template.Settings.min || 0));
		let Min = cdsDate.displayDate(monthNow.getTime(), {sequence: "y"});
		monthNow = new Date();
		monthNow.setFullYear(monthNow.getFullYear() + (template.Settings.max || 0));
		let Max = cdsDate.displayDate(monthNow.getTime(), {sequence: "y"});
		return {
			Min, 
			Max
		}
	},
	"time"(template, vue, fullName){
		return {
			Min: template.Settings.min || "00:00:00", 
			Max: template.Settings.max || "23:59:59"
		}
	},
	"date-time"(template, vue, fullName){
		let dateNow = cdsDate.getTime();
		let MinDate = cdsDate.displayDate(dateNow + (template.Settings.date.min || 0) * 24*60*60*1000, {sequence: "y-m-d"});
		let MaxDate = cdsDate.displayDate(dateNow + (template.Settings.date.max || 0) * 24*60*60*1000, {sequence: "y-m-d"});
		let MinTime = template.Settings.time.min || "00:00:00";
		let MaxTime = template.Settings.time.max || "23:59:59";

		return {
			Min:{
				date: MinDate,
				time: MinTime
			},
			Max:{
				date: MaxDate,
				time: MaxTime
			}
		}
	},
	"number"(template, vue, fullName){
		return {
			Min: (template.Settings.min || "").toString(), 
			Max: (template.Settings.max || "").toString()
		}
	},
	"boolean"(template, vue, fullName){
		return template.Settings.DefaultValue;
	},
	"JSON"(template, vue, fullName){
		return filterMain(template.children, vue, fullName);
	},
	"Array"(template, vue, fullName){
		return filterMain(template.children, vue, fullName);
	},
	"multi-choice"(template, vue, fullName){
		return vue[fullName + "Choices"].map(r=> r);
	},
}
function filterMain(template, vue, fullName){
	let output;
	fullName = fullName || "";
	if (template.constructor === [].constructor){	
		output = {};
		template.forEach(r=>{
			output[r.Name] = filterFun[r.Type[0]](r, vue, fullName + filterName(r.Name));
		})
	}
	else{
		output = filterFun[template.Type[0]](template, vue, fullName);
	}
	return output;
}


//------------------------- MISC -------------------------//
var valueChanger = function(input, elementNames, indices, itemTemplate, newValue){
	var output = cdsCopier.copy(input);
	var temp = cdsCopier.copy(itemTemplate).filter(r=>{
		return r.Name === elementNames[0];
	})[0];
	if (temp.Type[0] === "Array"){
		if (elementNames.length>1){
			// console.log("1: ")
			output[elementNames[0]][indices[0]] = valueChanger(output[elementNames[0]][indices[0]], indices.slice(1, indices.length), elementNames.slice(1,elementNames.length), temp.children, newValue);
		}
		else if (indices[0] != undefined){
			// console.log("2: ")
			output[elementNames[0]][indices[0]] = newValue;
		}
		else{
			// console.log("3")
			output[elementNames[0]] = newValue;
		}
	}
	else if (elementNames.length>1){
		// console.log("4: ")
		output[elementNames[0]] = valueChanger(output[elementNames[0]], elementNames.slice(1,elementNames.length), indices, temp.children, newValue);
	}
	else{
		// console.log("5: ")
		output[elementNames[0]] = newValue;
	}
	// console.log(output);

	return output;
}
function isSame(input1, input2){
	let arrayConstructor = [].constructor;
	let objectConstructor = {}.constructor;
	let output;
	if ([null, undefined].includes(input1) || [null, undefined].includes(input2)){
		return input1 === input2;
	}
	if (input1.constructor === arrayConstructor) {
		if (input2.constructor !== arrayConstructor || input1.length !== input2.length){
			return false;
		}
		return input1.every((item, itemIndex)=>{
			return isSame(item, input2[itemIndex]);
		})
	}
	else if (input1.constructor === objectConstructor) {
		if (input2.constructor !== objectConstructor){
			return false;
		}
		return Object.entries(input1).every(([key, value])=>{
			return isSame(value, input2[key]);
		})
	}
	return input1 === input2;
}

export default{
	init,
	checkMain,
	editMain,
	excelMain,
	excelUpload,
	filterMain,
	checkEmpty,
	checkStructure,
	valueChanger,
	isSame
}