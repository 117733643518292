<template>
	<div class="flex flex-col flex-1 bg-white">
		<Header @toggleNav = "toggleNav"></Header>

		<!--<div class="flex flex-1">-->
		<div class="mt-16">	
			<div class = "relative">
				<Nav ref = "sideNav"></Nav>
			</div>
			<router-view v-slot="{ Component }">
				<transition name="fade" mode="out-in"> 
					<component :is="Component"/>
				</transition>

			</router-view>
		</div>
	</div>
</template>

<script>
import Header from "./components/Header Footer/Header";
import Nav from "./components/Header Footer/Nav";
export default {
	components: {Header, Nav},
	data(){
		return {

		};
	},
	watch:{
		"$route.path"(){
			this.$refs.sideNav.hideNav();
		}
	},
	methods:{
		toggleNav(){
			this.$refs.sideNav.toggleNav();
		}
	}
};
</script>

<style lang="scss">
.fade-enter-from,
.fade-leave-to {
 opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: all opacity 0.5s ease-out;
}
</style>

