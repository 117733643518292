<template>
    <div>
          <section class="py-12 bg-white sm:py-16 lg:py-20">
          <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="max-w-md mx-auto text-left sm:text-center">
            <div class="max-w-xl mx-auto text-center">
                <p class="text-sm font-semibold tracking-widest text-blue-600 uppercase">4 Cosmetic Grades</p>

                <h2 class="mt-6 text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl">Grading Scale</h2>
            </div>
              <p class="mt-4 text-base font-medium text-gray-500">
                Our cosmetic grading is fair and consistant so you always know what you're getting.
              </p>
            </div>
    
            <div class="grid grid-cols-1 gap-8 mt-12 sm:mt-16 sm:grid-cols-2 lg:grid-cols-4">
              <div class="relative">
                <div class="absolute -inset-4">
                    <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" style="background: linear-gradient(90deg, #6589fb93 -0.55%, #6589fb93 62.86%)"></div>
                </div>
              <div class="border border-gray-50 rounded-2xl p-4 h-full relative overflow-hidden bg-white" >
                <h3 class="text-lg font-bold text-gray-900">
                  A Grade
                  <!--<span class="text-blue-400 font-normal text-sm ml-3 underline">
                    See Photos
                  </span>-->
                </h3>
                
                <hr class="mt-4 border-t-2 border-gray-900">
    
                <ul class="mt-6 space-y-6">
                  <li class="flex items-start">
                    <svg class="w-6 h-6 shrink-0 text-rose-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-3 text-base font-medium text-gray-500">
                      Like-new condition.  Unit will have little to no signs of use.  No dents or deep scratches.
                    </span>
                  </li>
    
                </ul>
              </div>
              </div>
              <div class="relative">
                <div class="absolute -inset-4">
                    <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" style="background: linear-gradient(90deg, #6589fb93 -0.55%,  #44ff9a93 68.36%)"></div>
                </div>
              <div class="border border-gray-50 rounded-2xl p-4 h-full relative overflow-hidden bg-white" >
                <h3 class="text-lg font-bold text-gray-900">
                  B Grade
                  <!--<span class="text-blue-400 font-normal text-sm ml-3 underline">
                    See Photos
                  </span>-->

                </h3>
                <hr class="mt-4 border-t-2 border-gray-900">
    
                <ul class="mt-6 space-y-6">
                  <li class="flex items-start">
                    <svg class="w-6 h-6 shrink-0 text-rose-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-3 text-sm font-medium text-gray-500">
                      Normal signs of use. The device may have some light scratching, nicks, or blemishes on the front & screen, sides, or back side.
                    </span>
                  </li>
                </ul>
              </div>
              </div>
              
              <div class="relative">
                <div class="absolute -inset-4">
                    <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" style="background: linear-gradient(90deg, #44ff9a93 -0.55%, #ebff7093 42.86%, #ebff7093 88.36%)"></div>
                </div>
              <div class="border border-gray-50 rounded-2xl p-4 h-full relative overflow-hidden bg-white" >                <h3 class="text-lg font-bold text-gray-900">
                  C Grade
                  <!--<span class="text-blue-400 font-normal text-sm ml-3 underline">
                    See Photos
                  </span>-->

                </h3>
                <hr class="mt-4 border-t-2 border-gray-900">
    
                <ul class="mt-6 space-y-6">
                  <li class="flex items-start">
                    <svg class="w-6 h-6 shrink-0 text-rose-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-3 text-sm font-medium text-gray-500">
                      The device will show heavier signs of use.  Deep scratches, nicks, blemishes, or small chips are acceptable.  The device will not have fully cracked glass.
                    </span>
                  </li>
                </ul>
              </div>
              </div>

              <div class="relative">
                <div class="absolute -inset-4">
                    <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" style="background: linear-gradient(90deg, #ebff7093 -0.55%, #ff664493 32.86%, #ff664493 48.36%, #ff664493 73.33%, #ff664493 99.34%)"></div>
                </div>
              <div class="border border-gray-50 rounded-2xl p-4 h-full relative overflow-hidden bg-white" >
                <h3 class="text-lg font-bold text-gray-900">
                  D Grade
                  <!--<span class="text-blue-400 font-normal text-sm ml-3 underline">
                    See Photos
                  </span>-->

                </h3>
                <hr class="mt-4 border-t-2 border-gray-900">
    
                <ul class="mt-6 space-y-6">
                  <li class="flex items-start">
                    <svg class="w-6 h-6 shrink-0 text-rose-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-3 text-sm font-medium text-gray-500">
                        The device is cosmetically damaged.  Cracked front and/or back glass, broken LCD/OLED panels, and cracked camera lens are acceptable.
                    </span>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </div>
        </section>

    <section class="py-10 bg-white sm:py-8 lg:py-10">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="max-w-md mx-auto text-left sm:text-center">
            <div class="max-w-xl mx-auto text-center">
                <h2 class="mt-6 text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Functionality Types</h2>
            </div>
              <p class="mt-4 text-base font-medium text-gray-500">
                Our devices feature three types of functionality that determine the readiness of an item for use.
              </p>
            </div>

            <div class="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-3 sm:mt-20 gap-x-4">
                <div class="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
                    <div class="flex items-start">
                        <svg class="flex-shrink-0 text-green-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div class="ml-5">
                            <h3 class="text-xl font-semibold text-black">Fully Functional</h3>
                            <p class="mt-3 text-base text-gray-600">Devices will not have any functional issues related to hardware and are ready for use. Minor notes can be found in a <span class="text-yellow-600 bg-yellow-50 border border-yellow-300 text-xxs font-semibold rounded-full inline-flex items-center px-2.5 py-0.5">Yellow Circle</span> below the item name.</p>
                        </div>
                    </div>

                    <div class="flex items-start">
                        <svg class="flex-shrink-0 text-yellow-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                        </svg>
                        <div class="ml-5">
                            <h3 class="text-xl font-semibold text-black">Repair</h3>
                            <p class="mt-3 text-base text-gray-600">These devices will have functional problems. The identified issues will be indicated by a <span class="text-red-600 bg-red-50 border border-red-300 text-xxs font-semibold rounded-full inline-flex items-center px-2.5 py-0.5">Red Circle</span> below the item name.</p>
                        </div>
                    </div>

                    <div class="flex items-start">
                        <svg class="flex-shrink-0 text-red-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                        </svg>
                        <div class="ml-5">
                            <h3 class="text-xl font-semibold text-black">Parts</h3>
                            <p class="mt-3 text-base text-gray-600">These devices will have problems that prevent them from being activated. They may include user locks or they may not turn on at all.</p>
                        </div>
                    </div>
                </div>

                <div class="lg:col-span-3">
                    <img class="w-full rounded-lg shadow-xl" src="" alt="" />
                </div>
            </div>
        </div>
    </section>
        
    </div>
    </template>
    
    <script>
export default {
  name: 'grading',
    
    }
    </script>
    
    <style>
    
    </style>