/*
	display(price, mode)
		- price: float
		- mode:
			- currency	: string
			- dp 		: number
*/
//======================================================//
//														//
//						CALCULATION						//
//														//
//======================================================//

//--------- OBTAIN ROUNDING TO NEAREST 5 CENTS ---------//
function roundingValue(price){
	price = parseFloat(price);
	// let declaration
	let ringgit, cents;
	let centDigits;

	// Calculation
	ringgit = Math.floor(price);
	cents = Math.round(100*(price - ringgit));
	centDigits = cents%10;

	if (centDigits < 3){
		return centDigits/100;
	}
	else if (centDigits < 6){
		return (centDigits - 5)/100;
	}
	else if (centDigits < 8){
		return (5 -  centDigits)/100;
	}
	else{
		return (10 - centDigits)/100;
	}
}


//-------------- ROUND TO NEAREST 5 CENTS --------------//
function roundedValue(price){
	price = parseFloat(price);
	return price + roundingValue(price);
}



//======================================================//
//														//
//						DISPLAY							//
//														//
//======================================================//

//---------------------- PRICE -------------------------//
function display(price, mode){
	price = parseFloat(price);
	// Mode
	let currency = mode.currency || "";
	let dp = mode.dp || 0;
	let signed = mode.signed || false;
	let whole, decimal, decimalOutput = "";

	// Obtains number
	if (price<0){
		currency = "- " + currency;
		price = Math.abs(price);
	}
	else if (signed){
		currency = "+ " + currency;
	}

	// Output
	return  currency + price.toFixed(dp);
}



//======================================================//
//														//
//						EXPORT							//
//														//
//======================================================//
export default{
	roundingValue,
	roundedValue,
	display,
}

