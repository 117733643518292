function copy(inputObject){
	let arrayConstructor = [].constructor;
	let objectConstructor = {}.constructor;

	let output;
	if (inputObject === null) {
		output = null;
	}
	else if (inputObject === undefined) {
		output = undefined;
	}
	else if (inputObject.constructor === arrayConstructor) {
		output = [];
		inputObject.forEach(element =>{
			output.push(copy(element));
		})
	}
	else if (inputObject.constructor === objectConstructor) {
		output = {};
		let item;
		for (item in inputObject){
			output[item] = copy(inputObject[item]);
		}
	}
	else {
		output = inputObject;
	}
	return output;
};
function copyAs(inputObject, value){
	let arrayConstructor = [].constructor;
	let objectConstructor = {}.constructor;

	let output;
	if (inputObject === null) {
		output = null;
	}
	else if (inputObject === undefined) {
		output = undefined;
	}
	else if (inputObject.constructor === arrayConstructor) {
		output = [];
		inputObject.forEach(element =>{
			output.push(copyAs(element, value));
		})
	}
	else if (inputObject.constructor === objectConstructor) {
		output = {};
		let item;
		for (item in inputObject){
			output[item] = copyAs(inputObject[item], value);
		}
	}
	else {
		output = value;
	}
	return output;
};
function countJSONKeys(input){
	let num = 0;
	for (let key in input){
		num++;
	}
	return num;
}
function arrayToJSON(input, key){
	input = copy(input) || [];
	let output = {};
	let IDKey = key || "ID";
	let ID;

	for (let index = 0; index < input.length; index++){
		ID = input[index][IDKey] || index.toString();
		output[ID] = copy(input[index]);
		delete output[ID][IDKey];
	}

	return output;
}
function jsonToArray(input){
	let output = [];
	let elemNow;

	for (let key in input){
		elemNow = copy(input[key]);
		elemNow.ID = key;
		output.push(elemNow);
	}
	return output;
}
function copySort(input){
	let output
	if (input === null) {
		output = null;
	}
	else if (input === undefined) {
		output = undefined;
	}
	else if (input.constructor === {}.constructor){
		output = {};
		Object.keys(input).sort().forEach(key=>{
			output[key] = copySort(input[key])
		})
	}
	else if (input.constructor === [].constructor){
		if (input.length > 0){
			if (input[0].constructor === {}.constructor){
				output = [];
				input.forEach(obj=>{
					output.push(copySort(obj));
				})
			}
			else if (input[0].constructor === [].constructor){
				output = [];
				input.forEach(obj=>{
					output.push(copySort(obj));
				})
			}
			else{
				output = copy(input);
				output.sort();
			}
		}
		else{
			output = [];
		}
	}
	else{
		output = input;
	}
	return output;
}
function cdsSort(input){
	let output = [];
	let currentObject = {}, settings = {};
	input.forEach(r=>{
		settings = {};
		Object.keys(r["Settings"]).sort().forEach(key=>{
			settings[key] = r["Settings"][key];
		})
		currentObject = {
			"Name": r["Name"],
			"Type": r["Type"],
			"Settings": r["Settings"],
			"Compulsory": r["Compulsory"],
			"Multiview": r["Multiview"],
			"Unique": r["Unique"],
		};
		if (r.children){
			currentObject["children"] = cdsSort(r.children);
		}
		output.push(currentObject);
	})
	return output;
}
function paste(json1, json2){
	// PASTE 1 ONTO 2
	if (json1 === null || json1 === undefined) {
		return json2;
	}
	else if (json2 === null || json2 === undefined) {
		return json1;
	}
	else if (json1.constructor === {}.constructor){
		Object.keys(json1).forEach(key=>{
			json2[key] = paste(json1[key], json2[key]);
		})
		return json2;
	}
	else if (json1.constructor === [].constructor){
		let maxLength = Math.min(json1.length, (json2 || []).length);

		for (let index = 0; index < maxLength; index++){
			json2[index] = paste(json1[index], json2[index]);
		}
		return json2;
	}
	else{
		return json1;
	}
}
export default {
	copy,
	copyAs,
	copySort,
	cdsSort,
	countJSONKeys,
	arrayToJSON,
	jsonToArray,
	paste
}