<template>


      <div class="py-4 ">
          <div class="container px-4 mx-auto sm:px-6 lg:px-8">
              <div class="flex items-center justify-between">
                  <div class="flex items-center -m-2 lg:hidden">
                      <button type="button" class="inline-flex items-center justify-center p-2 text-white bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-500">
                          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                          </svg>
                      </button>
                  </div>
  
                  <div class="flex items-center -m-2 lg:hidden">
                      <button type="button" class="inline-flex items-center justify-center p-2 text-white bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-500">
                          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                      </button>
                  </div>
  
                  <div class="hidden ilg:tems-center lg:space-x-12 lg:flex">
  
                      <router-link to="/inventory" title="phones" :class="'inline-flex items-center text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent hover:text-white ' + ($route.path === '/inventory' ? 'text-blue-50 border-gray-600': '')">
                         Cell Phones
                      </router-link>

                      <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-white"> Tablets </a>
  
                      <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-white"> Accessories </a>
  
                      <a href="#" title="" class="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-white"> Computers </a>
                  </div>
  
                 <!-- <div class="flex-1 hidden max-w-xs lg:block">
                      <label for="" class="sr-only"> Search </label>
                      <div class="relative">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                              </svg>
                          </div>
  
                          <input type="search" name="" id="" class="block w-full py-2 pl-10 text-white placeholder-gray-400 bg-gray-900 border-gray-600 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Search here" />
                      </div>
                  </div>-->
              </div>
          </div>
      </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'products',
  props: ['title'],
  data () {
    return {
      isOpen: false,
      scrollPosition: null,
      }
    },
}
</script>

<style>
</style>