//---------------------- IMPORTS -----------------------//
// VUE STUFF
import Vue from 'vue'							// VUE
import App from './App'							// BASE APP
import router from './router'					// ROUTER
import store from './store'						// STORE
import VueFB from "vue-firestore"				// VUE FIRESTORE
import VueModal from "vue-js-modal"				// MODAL
import VueToasted from "vue-toasted"			// TOASTED
import VueLazyLoad from 'vue-lazyload';			// LAZY LOAD FOR

// JS
import cds from "./assets/js/crud-ds-init.js"	// CRUD DS
import cdsDB from "./dbAuthManager.js"			// DATABASE AND AUTH MANAGER
import { saveAs } from 'file-saver';			// FILE SAVER

// CSS
import "./assets/css/style.css";

import './assets/tailwind.css'

//----------------------- INIT -------------------------//
Vue.use(VueFB);
Vue.use(VueModal);
Vue.use(VueToasted);
Vue.use(VueLazyLoad);
cds.init(Vue);
global.cdsDB = cdsDB


//------------------------ RUN -------------------------//
cdsDB.auth.onAuthStateChanged(user=>{
	new Vue({
		el: '#app',
		store,
		router,
		render: h => h(App)
	})
})
