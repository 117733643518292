//----------------------- IMPORT -------------------//
import myCopier from "./copyManager.js";			// COPY
import myDate from "./dateManager.js";				// FORMAT DATE
import myNum from "./priceManager.js";				// FORMAT PRICE
import myInput from "./inputManager.js";			// INPUT CHECKING
import jQuery from 'jquery';						// JQUERY
//------------------------ INIT --------------------//
function init(vue, t){
	//--------------------- DECLARE --------------------//
	global.cdsDate = myDate;
	cdsDate.setTimeZone(t || "");
	global.cdsCopier = myCopier;
	global.cdsNum = myNum;
	global.cdsInput = myInput;
	global.cdsCopy = function(input, string){
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = input;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		if (string){
			cdsNotify.toast(string || "Copied to clipboard.");
		}
	}
	global.cdsVueInit = function(vueData, itemTemplate){
		var vueData = vueData || {};
		var choicesExtractor = function(itemTemplate, name){
			var nameNow = "", c;
			itemTemplate.forEach(d=>{
				nameNow = "";
				for (var i = 0; i < d.Name.length; i++){
					c = d.Name[i];
					if ((c >= '0' && c <= '9') || (c.toLowerCase().match(/[a-z]/i))){
						nameNow += c;
					}
				}
				switch (d.Type[0]){
					case "text":
					case "tel":
						//
						vueData[name + nameNow + "MaxLength"] = (d.Settings.maxLength === null ? Infinity:d.Settings.maxLength).toString();
						break;
					//
					case "date":
						//
						if (d.Settings.min == 0 || d.Settings.min){
							vueData[name + nameNow + "Min"] = myDate.displayDate(new Date().getTime() + d.Settings.min*24*60*60*1000, {sequence: "y-m-d"});
						}
						else{
							vueData[name + nameNow + "Min"] = "";
						}
						if (d.Settings.max == 0 || d.Settings.max){
							vueData[name + nameNow + "Max"] = myDate.displayDate(new Date().getTime() + d.Settings.max*24*60*60*1000, {sequence: "y-m-d"});
						}
						else{
							vueData[name + nameNow + "Max"] = "";
						}
						break;
					//
					case "month":
						//
						let dateHandler = new Date();
						if (d.Settings.min == 0 || d.Settings.min){
							dateHandler.setMonth(dateHandler.getMonth() + (d.Settings.min || 0));
							vueData[name + nameNow + "Min"] = myDate.displayDate(dateHandler.getTime(), {sequence: "y-m"});
						}
						else{
							vueData[name + nameNow + "Min"] = "";
						}
						if (d.Settings.max == 0 || d.Settings.max){
							dateHandler = new Date();
							dateHandler.setMonth(dateHandler.getMonth() + (d.Settings.max || 0));
							vueData[name + nameNow + "Max"] = myDate.displayDate(dateHandler.getTime(), {sequence: "y-m"});
						}
						else{
							vueData[name + nameNow + "Max"] = "";
						}
						break;
					//
					case "year":
						//
						dateHandler = new Date();
						if (d.Settings.min == 0 || d.Settings.min){
							dateHandler.setFullYear(dateHandler.getFullYear() + (d.Settings.min || 0));
							vueData[name + nameNow + "Min"] = myDate.displayDate(dateHandler.getTime(), {sequence: "y"});
						}
						else{
							vueData[name + nameNow + "Min"] = "";
						}
						if (d.Settings.max == 0 || d.Settings.max){
							dateHandler = new Date();
							dateHandler.setFullYear(dateHandler.getFullYear() + (d.Settings.max || 0));
							vueData[name + nameNow + "Max"] = myDate.displayDate(dateHandler.getTime(), {sequence: "y"});
						}
						else{
							vueData[name + nameNow + "Max"] = "";
						}
						break;
					//
					case "number":
						//
						vueData[name + nameNow + "Min"] = (d.Settings.min || -Infinity).toString();
						vueData[name + nameNow + "Max"] = (d.Settings.max || Infinity).toString();
						break;
					//
					case "time":
						//
						vueData[name + nameNow + "Min"] = d.Settings.min.toString();
						vueData[name + nameNow + "Max"] = d.Settings.max.toString();
						vueData[name + nameNow + "Steps"] = (d.Settings.steps || 1).toString();

						break;
					//
					case "date-time":
						//
						var dateSetting = d.Settings.date;
						var timeSetting = d.Settings.time;
						if (dateSetting.min == 0 || dateSetting.min){
							vueData[name + nameNow + "DateMin"] = myDate.displayDate(new Date().getTime() + dateSetting.min*24*60*60*1000, {sequence: "y-m-d"});
						}
						else{
							vueData[name + nameNow + "DateMin"] = "";
						}
						if (dateSetting.max == 0 || dateSetting.max){
							vueData[name + nameNow + "DateMax"] = myDate.displayDate(new Date().getTime() + dateSetting.max*24*60*60*1000, {sequence: "y-m-d"});
						}
						else{
							vueData[name + nameNow + "DateMax"] = "";
						}
						vueData[name + nameNow + "TimeMin"] = timeSetting.min || "00:00:00.0001";
						vueData[name + nameNow + "TimeMax"] = timeSetting.max || "23:59:59";
						vueData[name + nameNow + "TimeSteps"] = timeSetting.steps || 1;
						break;
					//
					case "choice":
					case "multi-choice":
						//
						vueData[name + nameNow + "Choices"] = d.Settings["Generator Function"].split(",").map(r=>{
							return r.trim();
						})
						break;
					//
				}
				if (d.children){
					choicesExtractor(d.children, name + nameNow);				
				}
			})
		}
		choicesExtractor(itemTemplate, "");
		vueData["cdsFilterForm"] = cdsInput.filterMain(itemTemplate, vueData);
		vueData["useCdsFilter"] = false;
		return vueData;
	}
	global.$ = jQuery;
	global.jQuery = jQuery;
	global.pasteCode = async function(){
		return await navigator.clipboard.readText().then(text => {
			return text;
		});
	}
	//--------------------- FORMAT ---------------------//
	// DATE
	vue.prototype.formatDate = function(input, mode){
		return myDate.displayDate(input, mode);
	}
	vue.prototype.formatTime = function(input, mode){
		return myDate.displayTime(input, mode);
	}
	vue.prototype.reformatTime = function(input, mode){
		let ymd = this.formatDate(new Date().getTime(), {sequence: "y/m/d"});
		return myDate.displayTime(new Date(ymd + " " + input).getTime(), mode);
	}
	vue.prototype.formatDateTime = function(input, dateMode, timeMode){
		return myDate.displayDateTime(input, dateMode, timeMode);
	}
	// NUMBER
	vue.prototype.formatNum = function(input, mode){
		return myNum.display(input, mode);
	}
	//------------------- UPDATE VUE -------------------//
	vue.prototype.updateUI = function(){
		var form = myCopier.copy(this.form);
		var errorMsg = myCopier.copy(this.errorMsg);
		this.form = form;
		this.errorMsg = errorMsg;
	}
	//-------------------- SORTING ---------------------//
	vue.prototype.sortStart = function(listID){
		var listID = listID || "List"
		this.sorting = true;
		this.$nextTick(()=>{
			var sortable = $("#" + listID).sortable({
				delay: 50,
				disable: false
			});
			sortable.sortable("enable");
		});
	}
	vue.prototype.sortCancel = function(listID){
		var sortable = $("#" + listID).sortable("cancel");
		sortable.sortable("disable");
		this.sorting = false;
	}
	//--------------- ARRAY MANIPULATION ---------------//
	vue.prototype.addChoice = function(element, elementNames, elementIndices, choice){
		if (element.includes(choice)){
			element.splice(element.indexOf(choice),1);
		}
		else{
			var temp = myCopier.copy(this.itemTemplate);
			var argNum = 0;
			var form = this.form;
			var errorMsg = this.errorMsg;
			elementNames.forEach((name, nameIndex)=>{
				temp = temp.filter(r=>{
					return r.Name === name;
				})[0];
				form = form[name];
				errorMsg = errorMsg[name];
				if (temp.Type[0] === "Array" && nameIndex < elementNames.length - 1){
					form = form[elementIndices[argNum]];
					errorMsg = errorMsg[elementIndices[argNum]];
					argNum++;
					temp = temp.children;
				}
				else if (temp.Type[0] !== "multi-choice"){
					temp = temp.children;
				}
			});
			if (temp.Settings["maxLength"] === null || element.length < temp.Settings["maxLength"]){
				element.push(choice);
			}
		}
	}
	vue.prototype.addElement = function(elementNames, elementIndices){
		var temp = myCopier.copy(this.itemTemplate);
		var argNum = 0;
		var form = this.form;
		var errorMsg = this.errorMsg;
		elementNames.forEach((name, nameIndex)=>{
			temp = temp.filter(r=>{
				return r.Name === name;
			})[0];
			form = form[name];
			errorMsg = errorMsg[name];
			if(temp.Type[0] === "Array" && nameIndex < elementNames.length - 1){
				form = form[elementIndices[argNum]];
				errorMsg = errorMsg[elementIndices[argNum]];
				argNum++;
			}
			temp = temp.children;
		});
		var newElement = myInput.init(temp, this, elementNames.map(r=> r.replace(/ /g, '')).join(''));
		errorMsg.push(myCopier.copyAs(newElement, ""));
		form.push(newElement);
	}
	vue.prototype.toggleChoice = function(choices, choice){
		let index = choices.indexOf(choice)
		if (index == -1){
			choices.push(choice);
			return;
		}
		choices.splice(index, 1);
	}
	vue.prototype.duplicateElement = function(element, elementNames, elementIndices){
		var temp = myCopier.copy(this.itemTemplate);
		var argNum = 0;
		var form = this.form;
		var errorMsg = this.errorMsg;

		elementNames.forEach((name, nameIndex)=>{
			temp = temp.filter(r=>{
				return r.Name === name;
			})[0];
			form = form[name];
			errorMsg = errorMsg[name];
			if(temp.Type[0] === "Array" && nameIndex < elementNames.length - 1){
				form = form[elementIndices[argNum]];
				errorMsg = errorMsg[elementIndices[argNum]];
				argNum++;
			}
			temp = temp.children;
		});
		errorMsg.push(myCopier.copyAs(element, ""));
		form.push(myCopier.copy(element));
	}
	vue.prototype.updateUI = function(){
		this.errorMsg = cdsCopier.copyAs(this.form, "");
	}
	vue.prototype.moveElementUp = function(elements, elementIndex){
		if (elementIndex > 0){
			var temp = myCopier.copy(elements[elementIndex]);
			elements[elementIndex] = myCopier.copy(elements[elementIndex - 1]);
			elements[elementIndex - 1] = myCopier.copy(temp);
			this.updateUI();
		}
	}
	vue.prototype.moveElementDown = function(elements, elementIndex){
		if (elementIndex < elements.length -1){
			var temp = myCopier.copy(elements[elementIndex]);
			elements[elementIndex] = myCopier.copy(elements[elementIndex + 1]);
			elements[elementIndex + 1] = myCopier.copy(temp);
			this.updateUI();
		}
	}
	vue.prototype.removeElement = function(elementNames, elementIndices, elementIndex){
		var argNum = 0;
		var temp = cdsCopier.copy(this.itemTemplate);
		var form = this.form;
		var errorMsg = this.errorMsg;

		elementNames.forEach((name, nameIndex)=>{
			temp = temp.filter(r=>{
				return r.Name === name;
			})[0];
			form = form[name];
			errorMsg = errorMsg[name];
			if(temp.Type[0] === "Array" && nameIndex < elementNames.length - 1){
				form = form[elementIndices[argNum]];
				errorMsg = errorMsg[elementIndices[argNum]];
				argNum++;
			}
			temp = temp.children;
		});
		form.splice(elementIndex, 1);
		errorMsg.splice(elementIndex, 1);
	}
	//--------------- COPY PASTE FEATURE ---------------//
	vue.prototype.copyText = function(text){
		cdsCopy(text, "Copied to clipboard");
	}
	vue.prototype.copyElement = function(element){
		this.copyText(JSON.stringify(element));
	}
	vue.prototype.pasteElement = function(elementNames, elementIndices){
		var temp = cdsCopier.copy(this.itemTemplate);
		var form = this.form;
		var errorMsg = this.errorMsg;
		var argNum = 0;

		elementNames.forEach((name, nameIndex)=>{
			temp = temp.filter(r=>{
				return r.Name === name;
			})[0];
			form = form[name];
			if(temp.Type[0] === "Array" && nameIndex < elementNames.length-1){
				form = form[elementIndices[argNum]];
				errorMsg = errorMsg[elementIndices[argNum]];
				argNum++;
			}
			temp = temp.children;
		});
		navigator.clipboard.readText().then(text => {
			var element = JSON.parse(text);
			var structureResponse = myInput.checkStructure(element, temp);
			if (structureResponse.wrongStructure){
				cdsNotify.toast("WRONG DATA STRUCTURE!");
			}
			else{
				this.duplicateElement(structureResponse.output, elementNames, elementIndices);
			}
		});
	}
	vue.prototype.pasteJSON = async function(elementNames, elementIndices){
		var allowPaste = true;
		var argNum = 0;
		var temp = cdsCopier.copy(this.itemTemplate);
		var checkTemp;
		var form = this.form;
		var delay = 0;

		elementNames.forEach((name, nameIndex)=>{
			temp = temp.filter(r=>{
				return r.Name === name;
			})[0];
			form = form[name];
			if(temp.Type[0] === "Array" && nameIndex < elementNames.length && elementIndices[argNum] != undefined){
				form = form[elementIndices[argNum]];
				argNum++;
			}
			if (nameIndex < elementNames.length-1){
				temp = temp.children;
			}
		});
		checkTemp = cdsCopier.copy(temp);
		if (temp.Type[0] === "Array" && form.constructor === {}.constructor){
			checkTemp.Type[0] = "JSON";
		}
		var notEmpty = myInput.checkEmpty(form, temp);
		if (notEmpty){
			allowPaste = confirm("Confirm pasting? One or more of the children field(s) are filled in.");
			delay = 300;
		}
		if (allowPaste){
			$(document).focus();
			return new Promise((resolve, reject)=>{
				setTimeout(()=>{
					navigator.clipboard.readText().then(text => {
						try{
							var newForm = JSON.parse(text);
							var structureResponse = myInput.checkStructure(newForm, checkTemp);
							if (structureResponse.wrongStructure){
								cdsNotify.toast("WRONG DATA STRUCTURE!");
							}
							else{
								var oriForm = myCopier.copy(this.form);
								var oriErrorMsg = myCopier.copy(this.errorMsg);
								oriForm = myInput.valueChanger(oriForm, elementNames, elementIndices, this.itemTemplate, structureResponse.output);
								oriErrorMsg = myCopier.copyAs(oriForm, "");
								this.form = oriForm;
								this.errorMsg = oriErrorMsg;
							}
						}catch(e){
							cdsNotify.toast(e.message);
							resolve({
								success: false,
								msg: e.message
							})
						}
						return resolve({
							success: true
						});
					});
				},delay);
			})
		}
	}
	//------------------ EXCEL FEATURE -----------------//
	vue.prototype.filterExcel = async function(e) {
		this.excelError = "";
		// VAR DECLARATION
		var loads = [];
		var output = [];
		let fileList = e.target.files || e.dataTransfer.file;
		var errorMsg = [], error = false;

		// LOOP THROUGH EACH FILE
		for (var index = 0 ; index < fileList.length; index++){
			loads.push(this.loadExcel(fileList[index]));
		}
		return await Promise.all(loads).then(arrOfDocs=>{
			arrOfDocs.forEach(documents=>{
				error = documents.error || error;
				errorMsg = errorMsg.concat(documents.errorMsg);
				output = output.concat(documents.output);
			})
			return {
				documents: output,
				error,
				errorMsg
			}
		});
	}
	vue.prototype.loadExcel = async function(file){
		const XLSX = require("xlsx");
		var reader = new FileReader();
		var json_object, field;
		var allowAdd = true;
		var response;

		return new Promise((resolve, reject)=>{
			reader.onload = (e => {
				var data = e.target.result;
				var workbook = XLSX.read(data,{type: "binary"});
				var allItems = {};
				var response;
				var allowAdd = true;
				var editForm;
				var errorMsg = [];
				workbook.SheetNames.forEach((sheetName,sheetIndex) => {
					var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
					json_object = XL_row_object;
					json_object.forEach( (obj, objectIndex) =>{
						var nameNow = obj.ID || cdsDB.genID();
						try{
							if (allItems[nameNow] == undefined){
								response = myInput.excelUpload(obj, this.itemTemplate);
								if (response.error){
									allowAdd = false;
									errorMsg.push({
										Sheet: sheetIndex,
										Row: objectIndex,
										msg: response.errorMsg,
										fileName: file.name
									})
								}
								else{
									allItems[nameNow] = response.output
								}
							}
						}catch(e){
							allowAdd = false;
							errorMsg.push({
								Sheet: sheetIndex,
								Row: objectIndex,
								msg: e.message,
								fileName: file.name
							})
						}
					});
				});
				var output = [];
				for (var item in allItems){
					allItems[item].ID = item;
					output.push(allItems[item]);
				}
				if (allowAdd){
					return resolve({
						error: false,
						output: output,
						errorMsg: errorMsg
					})
				}
				else{
					return resolve({
						error: true,
						output: output,
						errorMsg: errorMsg
					})
				}
			});
			reader.onerror = function(ex) {
				cdsNotify.toast(ex);
			};
			reader.readAsBinaryString(file);
		})
	}
}
export default{
	init
}
