<template>
  <div>
      <!--<header :class="{change_color: scrollPosition > 50} " class="bg-gray-900 fixed inset-x-0 z-50">-->
    <header class="bg-gray-900 fixed inset-x-0 z-50">

      <div class="">
          <div class="container px-4 mx-auto sm:px-6 lg:px-8">
              <div class="flex justify-between h-16">
                  <div class="flex mr-auto">
                      <div class="flex items-center flex-shrink-0">
                          <img class="w-auto h-8" src="../../assets/img/logowhiteOLD.png" alt="" />
                      </div>
  
                      <div class=" sm:-my-px sm:ml-8 lg:flex lg:space-x-8">
                                <router-link to="/" title="" :class="'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent mb-1 hover:border-gray-200 hover:text-white ' + ($route.path === '/' ? 'text-blue-50 border-blue-200': '')">
                              <svg :class="'w-5 h-5 mr-2 text-gray-400 ' + ($route.path === '/' ? 'text-blue-50 transition-all duration-200 ': '')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                              </svg>
                              Home
                              <!-- <span class="text-xs uppercase ml-auto font-semibold text-blue-600 bg-white border border-transparent rounded-full inline-flex items-center px-2 py-0.5">
                                134 </span> -->
                          </router-link>
  
<!--                           <div class="inline-flex items-center px-1 pt-1 text-sm font-medium text-white transition-all duration-200 border-b-2 border-blue-200 mb-1" @click="isOpen = !isOpen">
                              <svg class="w-5 h-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                              </svg>
                              Products
                              <svg class="w-5 h-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                              </svg>
                          </div> -->

                          <router-link to="/inventory" title="" :class="'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent mb-1 hover:border-gray-200 hover:text-white ' + ($route.path === '/inventory' ? 'text-blue-50 border-blue-200': '')">
                              <svg :class="'w-5 h-5 mr-2 text-gray-400 ' + ($route.path === '/inventory' ? 'text-blue-50 transition-all duration-200 ': '')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                              </svg>
                              Inventory
                          </router-link>
  
                                <router-link to="/grading" title="" :class="'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent mb-1 hover:border-gray-200 hover:text-white ' + ($route.path === '/grading' ? 'text-blue-50 border-blue-200': '')">
                              <svg :class="'w-5 h-5 mr-2 text-gray-400 ' + ($route.path === '/grading' ? 'text-blue-50 transition-all duration-200 ': '')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                              </svg>
                              Grading Scale
                          </router-link>

                                <router-link to="/contact" title="" :class="'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent mb-1 hover:border-gray-200 hover:text-white ' + ($route.path === '/contact' ? 'text-blue-50 border-blue-200': '')">
                              <svg :class="'w-5 h-5 mr-2 text-gray-400 ' + ($route.path === '/contact' ? 'text-blue-50 transition-all duration-200 ': '')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                              </svg>
                              Contact
                          </router-link>



                            <!--
                          <a href="#" title="" class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent hover:border-gray-200 hover:text-white">
                              <svg class="w-5 h-5 mr-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                              Customers
                              <svg class="w-5 h-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                              </svg>
                          </a>
  
                          <a href="#" title="" class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-400 transition-all duration-200 border-b-2 border-transparent hover:border-gray-200 hover:text-white">
                              <svg class="w-5 h-5 mr-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                              </svg>
                              Support
                              <svg class="w-5 h-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                              </svg>
                          </a>-->
                      </div>
                  </div>
  

              </div>
          </div>
      </div>
  
                             <!-- <Products title="Products" class="transition ease-in-out" v-if="isOpen">
                             </Products> -->

  </header>
  
  </div>
  </template>
  
<script>
import Products from '../../components/Header Footer/Products'
export default {
  name: 'navbar',
  components: {
    Products
  },
  data () {
    return {
      isOpen: false,
      scrollPosition: null,
      }
      
    },
    methods: {
    updateScroll() {
       this.scrollPosition = window.scrollY
    }
    },
    mounted() {
    window.addEventListener('scroll', this.updateScroll);
    },
}
</script>
  
  <style>
     .change_color {
       /*background-color: rgba(17, 24, 39, 0.5) !important;*/
       background: linear-gradient(180deg, rgba(17,24,39,0.2) 90%, rgba(17,24,39,0) 100%) !important;
       color:rgba(30, 255, 0, 0.5) !important;
       transition: background-color 0.5s;
       -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);   
   }
  </style>